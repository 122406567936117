export const tableColumns = [
	{
		model: 'QuestionSk',
		i18n: 'questionsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'TargetType',
		i18n: 'type',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'Order',
		i18n: 'order',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'IsActive',
		i18n: 'active',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'CreatedOn',
		i18n: 'created',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
];

<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'Id', ascending: 1 }"
		modelId="Id"
		headerTitle="faqgoogle2884"
		tooltipMsg="manualforfaqgoo"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="FaqTable"
	>
		<template #TargetType="list">{{ formatType(list.row.TargetType) }}</template>

		<template #CreatedOn="list">{{ list.row.CreatedOn | dateTime }}</template>

		<template #IsActive="list">
			<div class="d-flex flex-row align-items-center justify-content-start">
				<boolean-display :boolValue="list.row.IsActive"></boolean-display>
			</div>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="+list.row.CountryId"></country-flag>
		</template>

		<template #customActions="list">
			<megau-button
				v-if="showDeleteButton()"
				classprop="btn btn-customdanger ml-1"
				icon="trash-alt"
				:tooltip="$t('delete')"
				@handleClick="openModal(list.row.Id)"
			></megau-button>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import { setDropdownOptions } from '@/components/general/utils';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '../../services/service/common.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './faq.table-data';

export default {
	name: 'faqTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'Faq',
			columns: tableColumns,

			TypeDict: serviceEnums.getEnumDict('faqTargetType'),
		};
	},

	async created() {
		this.columns = this.prepareColumns(tableColumns);
	},

	methods: {
		prepareColumns(columns) {
			let values = serviceEnums.getEnumForDropdown('faqTargetType', true);
			return setDropdownOptions(columns, 'TargetType', values);
		},

		formatType(type) {
			if (type > 0) {
				return this.TypeDict[type].Text;
			}
			return '-';
		},

		refresh() {
			this.tableKey += 1;
		},

		redirectToDetail(itemId = 0) {
			this.$router.push({
				name: links.faqDetailName,
				params: { faqId: itemId },
			});
		},

		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},

		openModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteThisItem);
		},

		deleteThisItem(id) {
			serviceCommon.deleteItem(id, this.tableGetData, this.controllerName);
		},
	},
};
</script>
<style lang="scss">
.FaqTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 900px;
		width: 900px;
	}
}
</style>

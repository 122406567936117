var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "FaqTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "Id", ascending: 1 },
          modelId: "Id",
          headerTitle: "faqgoogle2884",
          tooltipMsg: "manualforfaqgoo",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "TargetType",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatType(list.row.TargetType)))]
              },
            },
            {
              key: "CreatedOn",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm._f("dateTime")(list.row.CreatedOn)))]
              },
            },
            {
              key: "IsActive",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-start",
                    },
                    [
                      _c("boolean-display", {
                        attrs: { boolValue: list.row.IsActive },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: +list.row.CountryId },
                  }),
                ]
              },
            },
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _vm.showDeleteButton()
                    ? _c("megau-button", {
                        attrs: {
                          classprop: "btn btn-customdanger ml-1",
                          icon: "trash-alt",
                          tooltip: _vm.$t("delete"),
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.openModal(list.row.Id)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          false,
          1790096141
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }